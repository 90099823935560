import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingAcademicExperienceForm } from '../../forms';
import { ListingLink } from '..';

import css from './EditListingAcademicExperiencePanel.module.css';

const FEATURES_NAME = 'yogaStyles';

const EditListingEducationPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingAcademiceExperiencePanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingAcademiceExperiencePanel.createListingTitle" />
  );

  
  const academicExperiences = publicData && publicData.academicExperiences;
  const initialValues = { academicExperiences };

  console.log(publicData);
  console.log(initialValues);

  const handleHiddenAcademicExperiencesField = () => {
    const academicExperiencesForms = document.getElementsByClassName('academic-experience-form');
    const academicExperiences = [];
    const academicInstitutions = [];
    const academicSectors = [];
    const academicSubjects = [];



    Array.from(academicExperiencesForms).forEach(form => {
      let academicExperience = {}

      const inputs = form.querySelectorAll('input');
      const selects = form.querySelectorAll('select');
      const textAreas = form.querySelectorAll('textarea');

      const institution = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'institution';
      }).value;

      const institutionDomain = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'institutionDomain';
      }).value;

      const sector = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'sector';
      }).value;

      const subject = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'subject';
      }).value;

      const degreeType = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'degreeType';
      }).value;

      const undergradOrPostgrad = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'undergradOrPostgrad';
      }).value;

      const years = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'years';
      }).value

      const accolades = Array.from(textAreas).find(obj => {
        return obj.getAttribute('aria-label') === 'accolades';
      }).value

      if (/\S/.test(institution)) {
        academicExperience = { ...academicExperience, institution, institutionDomain, sector, subject, degreeType, undergradOrPostgrad, years, accolades };
        academicExperiences.push({ academicExperience });
        academicInstitutions.push(institution);
        academicSectors.push(sector);
        academicSubjects.push(subject);
      }
    });

    return { academicExperiences, academicInstitutions, academicSectors, academicSubjects };
  }

  const addInitialValues = () => {
    console.log(initialValues);
    let academicExperienceInitialValues = {}
    const academicExperiencesForms = document.getElementsByClassName('academic-experience-form');

    Array.from(academicExperiencesForms).forEach((form, index) => {
      const inputs = form.querySelectorAll('input');
      const selects = form.querySelectorAll('select');
      const textAreas = form.querySelectorAll('textarea');

      const institution = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'institution';
      }).value;

      const institutionLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'institution';
      }).name;

      const institutionDomain = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'institutionDomain';
      }).value;

      const institutionDomainLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'institutionDomain';
      }).name;
      

      const sector = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'sector';
      }).value;

      const sectorLabel = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'sector';
      }).name;


      const subject = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'subject';
      }).value;

      const subjectLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'subject';
      }).name;


      const degreeType = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'degreeType';
      }).value;

      const degreeTypeLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'degreeType';
      }).name;


      const undergradOrPostgrad = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'undergradOrPostgrad';
      }).value;

      const undergradOrPostgradLabel = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'undergradOrPostgrad';
      }).name;

      const years = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'years';
      }).value

      const yearsLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'years';
      }).name;

      const accolades = Array.from(textAreas).find(obj => {
        return obj.getAttribute('aria-label') === 'accolades';
      }).value

      const accoladesLabel = Array.from(textAreas).find(obj => {
        return obj.getAttribute('aria-label') === 'accolades';
      }).name;

      academicExperienceInitialValues = { ...academicExperienceInitialValues, [institutionLabel]: institution, [institutionDomainLabel]: institutionDomain, [sectorLabel]: sector, [subjectLabel]: subject, [degreeTypeLabel]: degreeType, [undergradOrPostgradLabel]: undergradOrPostgrad, [yearsLabel]: years, [accoladesLabel]: accolades  }

    });

    if (initialValues && initialValues.academicExperiences && initialValues.academicExperiences.length < 1) {
      return initialValues
    }

    console.log({ ...initialValues, ...academicExperienceInitialValues})

    return { ...initialValues, ...academicExperienceInitialValues };
  }

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingAcademicExperienceForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={addInitialValues()}
        onSubmit={values => {          
          const { academicExperiences: academicExperiencesInfo, academicInstitutions, academicSectors, academicSubjects } = handleHiddenAcademicExperiencesField();
          const { academicExperiences = [] } = values;
          const reformattedValues = { publicData: { academicExperiences: academicExperiencesInfo, academicInstitutions: academicInstitutions.toString(), academicSectors: academicSectors.toString(), academicSubjects: academicSubjects.toString() }  }
          onSubmit(reformattedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingEducationPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingEducationPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingEducationPanel;
