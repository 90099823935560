import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, LocationAutocompleteInput } from '../../components';

import css from './TopbarSearchForm.module.css';

const identity = v => v;
var search_terms = ['apple', 'apple watch', 'apple macbook', 'apple macbook pro', 'iphone', 'iphone 12'];
const searchTerms = [
  {
    title: 'finance',
    href: '/field/finance'
  },
  {
    title: 'investment banking',
    href: '/field/finance/investment-banking'
  },
  {
    title: 'investment management',
    href: '/field/finance/investment-management',
  },
  {
    title: 'sales',
    href: '/field/finance/sales-and-trading',
  },
  {
    title: 'trading',
    href: '/field/finance/sales-and-trading',
  },
  {
    title: 'sales and trading',
    href: '/field/finance/sales-and-trading',
  },
  {
    title: 'investment research',
    href: '/field/finance/investment-research',
  },
  {
    title: 'risk management',
    href: '/field/finance/risk-management',
  },
  {
    title: 'chartered accountants',
    href: '/field/finance/chartered-accountants',
  },
  {
    title: 'acturial scientist',
    href: '/field/finance/acturial-scientist',
  },

  {
    title: 'law',
    href: '/field/law'
  },
  {
    title: 'solicitor',
    href: '/field/law/solicitor'
  },
  {
    title: 'barrister',
    href: '/field/law/barrister'
  },

  {
    title: 'healthcare',
    href: '/field/healthcare'
  },
  {
    title: 'medicine',
    href: '/field/healthcare/medicine'
  },
  {
    title: 'dentistry',
    href: '/field/healthcare/dentistry'
  },
  {
    title: 'pharmacy',
    href: '/field/healthcare/pharmacy'
  },
  {
    title: 'veterinary',
    href: '/field/healthcare/veterinary'
  },

  {
    title: 'civil service',
    href: '/field/civil-service'
  },
  {
    title: 'politics and administration',
    href: '/field/civil-service/politics-and-administration'
  },

  {
    title: 'business',
    href: '/field/business'
  },
  {
    title: 'general management',
    href: '/field/business/general-management'
  },
  {
    title: 'management consultancy',
    href: '/field/business/management-consultancy'
  },
  {
    title: 'start ups',
    href: '/field/business/start-ups'
  },

  {
    title: 'engineering',
    href: '/field/engineering'
  },
  {
    title: 'mechanical',
    href: '/field/engineering/mechanical'
  },
  {
    title: 'aeronautical',
    href: '/field/engineering/aeronautical'
  },
  {
    title: 'civil',
    href: '/field/engineering/civil'
  },
  {
    title: 'electrical',
    href: '/field/engineering/electrical'
  },
  {
    title: 'chemical',
    href: '/field/engineering/chemical'
  },

  {
    title: 'technology',
    href: '/field/technology'
  },
  {
    title: 'developer',
    href: '/field/technology/developer-software-engineer'
  },
  {
    title: 'software engineer',
    href: '/field/technology/developer-software-engineer'
  },
  {
    title: 'support',
    href: '/field/technology/support'
  },
  {
    title: 'system analysts',
    href: '/field/technology/system-analysts'
  },
  {
    title: 'web developer',
    href: '/field/technology/web-developer'
  },

  {
    title: 'media',
    href: '/field/media'
  },
  {
    title: 'science',
    href: '/field/science'
  },


];

function autocompleteMatch(input) {
  if (input == '') {
    return [];
  }
  var reg = new RegExp(input)
  return searchTerms.filter(function(item) {
	  if (item.title.match(reg)) {
  	  return item;
	  }
  });
}

function showResults(val, location) {
  const dropDown = document.querySelectorAll('[data-dropdown-location="' + location + '"]')[0];
  dropDown.innerHTML = '';
  let list = '';
  let terms = autocompleteMatch(val);
  let reducedTerms = terms.slice(0, 5) 
  for (var i = 0; i < reducedTerms.length; i++) {
    list += '<li><a href="' + reducedTerms[i].href + '" style="color:white">' + reducedTerms[i].title + '</a></li>';
  }
  if (!list || !val || val.trim() == '') {
    dropDown.innerHTML = '';
  } else {
    dropDown.innerHTML = '<ul>' + list + '</ul>'; 
  }
}

const onBlurHandler = (e) => {
  console.log("on blur");
  if (e && e.target) {
    const location = e.target.getAttribute('data-input-location');
    if (location == 'header-desktop' || location == mobile ) {
      const dropDown = document.querySelectorAll('[data-dropdown-location="' + location + '"]')[0];
      dropDown.innerHTML = '';
    }
  }
}

const onChangeHandler = (e) => {
    if (e && e.target) {
      console.log(e);
      const location = e.target.getAttribute('data-input-location');
      if (location !== 'landing') {
        showResults(e.target.value, location);
      }
    }
  }
class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
    this.placeholder = props.placeholder;
  }

  onSubmit(values) {
    this.props.onSubmit({ keywords: values.keywords });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            isMobile,
            location,
            handleSubmit,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.autoComplete}>
                <Field
                name="keywords"
                // onKeyDown={onChangeHandler()}
                render={({ input, meta }) => {
                  return (
                    <input
                      className={
                        isMobile
                          ? css.mobileInputRoot
                          : css.desktopInputRoot
                      }
                      {...input}
                      id="keyword-search"
                      ref={this.searchInput}
                      type="text"
                      placeholder={this.placeholder || intl.formatMessage({
                        id: 'TopbarSearchForm.placeholder',
                      })}
                      onKeyUp={onChangeHandler}
                      onBlur={onBlurHandler}
                      autoComplete="off"
                      data-input-location={location}
                    />
                  );
                }}
              />
              </div>
              <div className={css.dropdownResultsContainer} data-dropdown-results-container-location={location}>
                <div className={css.dropdownResultsInnerContainer} data-dropdown-results-inner-container-location={location}>
                  <div className={css.dropdownResults} data-dropdown-location={location}>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
