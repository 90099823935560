import React, { useState, useEffect, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import config from '../../config';
import { Button, Form, FieldTextInput, FieldSelect, IconClose, IconAdd  } from '../../components';
import { CustomCertificateSelectFieldMaybe } from '../EditListingDescriptionForm/CustomCertificateSelectFieldMaybe';

import css from './EditListingAcademicExperience.module.css';

const EditListingAcademicExperienceFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        invalid,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl, 
        initialValues,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { academicExperiences } = initialValues;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;


      const institutionMessage = intl.formatMessage({ id: 'EditListingAcademicExperienceForm.institutionMessage' });
      const institutionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.institutionPlaceholder',
      });
      const institutionRequiredMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.institutionRequiredMessage',
      });


      const subjectMessage = intl.formatMessage({ id: 'EditListingAcademicExperienceForm.subjectMessage' });
      const subjectPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.subjectPlaceholder',
      });
      const subjectRequiredMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.subjectRequiredMessage',
      });


      const sectorRequiredMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.sectorRequiredMessage',
      });
      const undergradOrPostgradRequiredMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.undergradOrPostgradRequiredMessage',
      });


      const degreeTypeMessage = intl.formatMessage({ id: 'EditListingAcademicExperienceForm.degreeTypeMessage' });
      const degreeTypePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.degreeTypePlaceholder',
      });
      const degreeTypeRequiredMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.degreeTypeRequiredMessage',
      });

      const yearsMessage = intl.formatMessage({ id: 'EditListingAcademicExperienceForm.yearsMessage' });
      const yearsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.yearsPlaceholder',
      });
      const yearsRequiredMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.yearsRequiredMessage',
      });


      const accoladesMessage = intl.formatMessage({ id: 'EditListingAcademicExperienceForm.accoladesMessage' });
      const accoladesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAcademicExperienceForm.accoladesPlaceholder',
      });
      
      const academicExperiencesFormClass = `${css.form}  academic-experience-form`;

      const clearDropdown = (dropdown) => {
        document.getElementById(dropdown).innerHTML = '';
      }

      const handleDropdownClick = (e, dropdownId, index) => {
        const companyDataStr = document.getElementById(e.target.id).getAttribute('data-company');
        const companyDataJSON = JSON.parse(companyDataStr);

        // console.log(document.getElementById(`${companyDataJSON.inputId}-${index}`));
        console.log(`${companyDataJSON.inputId}`);

        document.getElementById(companyDataJSON.inputId).value = companyDataJSON.name;
        document.getElementById(`${companyDataJSON.inputId}-domain`).value = companyDataJSON.domain;
        clearDropdown(dropdownId);

      }

      const clearDomainField = (containerId) => {
        document.getElementById(`${containerId}-domain`).value = '';
      }

      const populateDropdown = (results, container) => {
        const dropdown = document.getElementById(`${container}-dropdown`);
        let dropdownItems = ''

        results.forEach((result, index) => {
          const escapedName = result.name.replace(/'/g, "");
          const companyObjStr = JSON.stringify({
            logo: result.logo,
            name: escapedName,
            domain: result.domain,
            inputId: container
          });
          const dropdownItem = `<div id="${container}-dropdown-item-${index}" class="dropdown-item" data-company='${companyObjStr}'><img src="${result.logo}" id="${container}-dropdown-item-${index}-img" onerror="this.style.display='none'" data-company='${companyObjStr}'/>${result.name} <span id="${container}-dropdown-item-${index}-span" data-company='${companyObjStr}'>${result.domain}</span></div>`;
          dropdownItems = dropdownItems + dropdownItem;
        });

        dropdown.innerHTML = dropdownItems;
      }

      const handleOnInput = (e) => {
        const containerId = e.target.id;
        clearDomainField(containerId);

        fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=:${e.target.value}`)
          .then(res => res.json())
          .then((result) => {
            console.log(result, containerId);
            populateDropdown(result, containerId);
          }),
          (error) => {
            console.log("logo error ", error);
          }
      }

      const academicExperienceForm = (count) => {
        return (
          <div className={academicExperiencesFormClass} key={`academic-experience-field-container-${count}`}>
            <div>
              <FieldTextInput
                id={`institution${count}`}
                name={`institution${count}`}
                className={css.field}
                type="text"
                label={institutionMessage}
                placeholder={institutionPlaceholderMessage}
                autoFocus
                autoComplete="off"
                aria-label="institution"
                validate={composeValidators(required(institutionRequiredMessage))}
                onInput={e => handleOnInput(e)}
                onFocus={e => e.preventDefault}
              />

              <div id={`institution${count}-dropdown`} className={css.dropdown} onClick={e => handleDropdownClick(e, `institution${count}-dropdown`, count)} />

              <FieldTextInput
                id={`institution${count}-domain`}
                name={`institution${count}-domain`}
                className={css.institutionDomain}
                type="text"
                label="domain"
                aria-label="institutionDomain"
                autoComplete="off"
              />
            </div>

            <FieldTextInput
              id={`subject${count}`}
              name={`subject${count}`}
              className={css.field}
              type="text"
              label={subjectMessage}
              placeholder={subjectPlaceholderMessage}
              autoComplete="off"
              aria-label="subject"
              validate={composeValidators(required(subjectRequiredMessage))}
            />

            <FieldSelect id={`sector${count}`} name={`sector${count}`} className={css.field} defaultValue="medicine" label="sector" aria-label="sector">
              <optgroup label="healthcare">
                <option value="medicine">medicine</option>
                <option value="dentistry">dentistry</option>
                <option value="pharmacy">pharmacy</option>
                <option value="veterinary">venterinary</option>
              </optgroup>
              <optgroup label="engineering">
                <option value="mechanical">mechanical</option>
                <option value="aeronautical">aeronautical</option>
                <option value="civil">civil</option>
                <option value="electrical">electrical</option>
              </optgroup>
              <optgroup label="business">
                <option value="management consultancy">management consultancy</option>
                <option value="general management">general management</option>
                <option value="start-ups">start-ups</option>
              </optgroup>
              <optgroup label="finance">
                <option value="investment banking">investment banking</option>
                <option value="sales + trading">sales + trading</option>
                <option value="investment research">investment research</option>
                <option value="risk management">risk management</option>
                <option value="chartered accountants">chartered accountants</option>
                <option value="general finance">general finance</option>
                <option value="actuarial scientist">actuarial scientist</option>
              </optgroup>
              <optgroup label="law">
                <option value="solicitor">solicitor</option>
                <option value="barrister">barrister</option>
              </optgroup>
              <optgroup label="technology">
                <option value="developer/ software engineer">developer/ software engineer</option>
                <option value="support">support</option>
              </optgroup>
            </FieldSelect>

            <FieldTextInput
              id={`degreeType${count}`}
              name={`degreeType${count}`}
              className={css.field}
              type="text"
              label={degreeTypeMessage}
              placeholder={degreeTypePlaceholderMessage}
              autoComplete="off"
              aria-label="degreeType"
              validate={composeValidators(required(degreeTypeRequiredMessage))}
            />

            <FieldSelect id={`undergradOrPostgrad${count}`} defaultValue="undergraduate" name={`undergradOrPostgrad${count}`} className={css.field} label="undergraduate/postgraduate" aria-label="undergradOrPostgrad">
                <option disabled value="default"> select an option </option>
                <option value="undergraduate">undergraduate</option>
                <option value="postgraduate">postgraduate</option>
            </FieldSelect>

            <FieldTextInput
              id={`years${count}`}
              name={`years${count}`}
              className={css.field}
              type="text"
              label={yearsMessage}
              placeholder={yearsPlaceholderMessage}
              autoComplete="off"
              aria-label="years"
              validate={composeValidators(required(yearsRequiredMessage))}
            />

            <FieldTextInput
              id={`accolades${count}`}
              name={`accolades${count}`}
              className={css.field}
              type="textarea"
              label={accoladesMessage}
              placeholder={accoladesPlaceholderMessage}
              autoComplete="off"
              aria-label="accolades"
            />

            <button type="button" className={css.removeButton} onClick={
              () => {
                const filterObj = Array.isArray(academicExperienceFieldsRef.current) ? academicExperienceFieldsRef.current : [academicExperienceFieldsRef.current];
                const data = (filterObj).filter(obj => obj.key !== `academic-experience-field-container-${count}`);
                setAcademicExperienceFields(data);
              }
            }><IconClose rootClassName={css.closeIcon} /></button>
          </div>
        )
      }

      const initialForm = (count) => {
        if (academicExperiences && academicExperiences.length > 0) {
          const existingAcademicExperienceFields =
            academicExperiences.map((item, index) => {
              const { institution, institutionDomain, subject, sector, degreeType, undergradOrPostgrad, years, accolades } = item.academicExperience;

              console.log(sector);
              console.log(index);

              return (
                <div className={academicExperiencesFormClass} key={`academic-experience-field-container-${index}`}>
                  <div>
                    <FieldTextInput
                      id={`institution${index}`}
                      name={`institution${index}`}
                      className={css.field}
                      type="text"
                      label={institutionMessage}
                      placeholder={institutionPlaceholderMessage}
                      initialValue={institution}
                      autoFocus
                      autoComplete="off"
                      aria-label="institution"
                      onInput={e => handleOnInput(e)}
                      onFocus={e => e.preventDefault}
                      validate={composeValidators(required(institutionRequiredMessage))}
                    />

                    <div id={`institution${index}-dropdown`} className={css.dropdown} onClick={e => handleDropdownClick(e, `institution${index}-dropdown`, index)} />

                    <FieldTextInput
                      id={`institution${index}-domain`}
                      name={`institution${index}-domain`}
                      className={css.institutionDomain}
                      type="text"
                      label="domain"
                      aria-label="institutionDomain"
                      initialValue={institutionDomain}
                      autoComplete="off"
                    />
                  </div>

                  <FieldTextInput
                    id={`subject${index}`}
                    name={`subject${index}`}
                    className={css.field}
                    type="text"
                    label={subjectMessage}
                    placeholder={subjectPlaceholderMessage}
                    initialValue={subject}
                    autoComplete="off"
                    aria-label="subject"
                    validate={composeValidators(required(subjectRequiredMessage))}
                  />

                  <FieldSelect id={`sector${index}`} name={`sector${index}`} className={css.field} initialValue={sector} label="sector" aria-label="sector" validate={composeValidators(required(sectorRequiredMessage))}>
                    <optgroup label="healthcare">
                      <option value="medicine">medicine</option>
                      <option value="dentistry">dentistry</option>
                      <option value="pharmacy">pharmacy</option>
                      <option value="veterinary">venterinary</option>
                    </optgroup>
                    <optgroup label="engineering">
                      <option value="mechanical">mechanical</option>
                      <option value="aeronautical">aeronautical</option>
                      <option value="civil">civil</option>
                      <option value="electrical">electrical</option>
                    </optgroup>
                    <optgroup label="business">
                      <option value="management consultancy">management consultancy</option>
                      <option value="general management">general management</option>
                      <option value="start-ups">start-ups</option>
                    </optgroup>
                    <optgroup label="finance">
                      <option value="investment banking">investment banking</option>
                      <option value="sales + trading">sales + trading</option>
                      <option value="investment research">investment research</option>
                      <option value="risk management">risk management</option>
                      <option value="chartered accountants">chartered accountants</option>
                      <option value="general finance">general finance</option>
                      <option value="actuarial scientist">actuarial scientist</option>
                    </optgroup>
                    <optgroup label="law">
                      <option value="solicitor">solicitor</option>
                      <option value="barrister">barrister</option>
                    </optgroup>
                    <optgroup label="technology">
                      <option value="developer/ software engineer">developer/ software engineer</option>
                      <option value="support">support</option>
                    </optgroup>
                  </FieldSelect>

                  <FieldTextInput
                    id={`degreeType${index}`}
                    name={`degreeType${index}`}
                    className={css.field}
                    type="text"
                    label={degreeTypeMessage}
                    placeholder={degreeTypePlaceholderMessage}
                    initialValue={degreeType}
                    autoComplete="off"
                    aria-label="degreeType"
                    validate={composeValidators(required(degreeTypeRequiredMessage))}
                  />

                  <FieldSelect id={`undergradOrPostgrad${index}`} name={`undergradOrPostgrad${index}`} className={css.field} initialValue={undergradOrPostgrad} label="undergraduate/postgraduate" aria-label="undergradOrPostgrad" validate={composeValidators(required(undergradOrPostgradRequiredMessage))}>
                    <option value="undergraduate">undergraduate</option>
                    <option value="postgraduate">postgraduate</option>
                  </FieldSelect>

                  <FieldTextInput
                    id={`years${index}`}
                    name={`years${index}`}
                    className={css.field}
                    type="text"
                    label={yearsMessage}
                    placeholder={yearsPlaceholderMessage}
                    initialValue={years}
                    autoComplete="off"
                    aria-label="years"
                    validate={composeValidators(required(yearsRequiredMessage))}
                  />

                  <FieldTextInput
                    id={`accolades${index}`}
                    name={`accolades${index}`}
                    className={css.field}
                    type="textarea"
                    label={accoladesMessage}
                    placeholder={accoladesPlaceholderMessage}
                    initialValue={accolades}
                    autoComplete="off"
                    aria-label="accolades"
                  />


                  
                  <button type="button" className={css.removeButton} onClick={
                    () => {
                      const data = (academicExperienceFieldsRef.current).filter(obj =>  obj.key !== `academic-experience-field-container-${index}`);
                      console.log(data);
                      setAcademicExperienceFields(data);
                    }
                  }><IconClose rootClassName={css.closeIcon} /></button>
                </div>
              );

            });

          return existingAcademicExperienceFields;

        }

        return (academicExperienceForm(count))


      }

      const academicExperienceCountDefaultValue = academicExperiences && academicExperiences.length > 0 ? academicExperiences.length - 1  : 0;

      const [academicExperienceFormCount, setAcademicExperienceFormCount] = useState(academicExperienceCountDefaultValue);
      const [academicExperienceFields, setAcademicExperienceFields] = useState(initialForm(academicExperienceFormCount));
      const academicExperienceFieldsRef = useRef(academicExperienceFields);
      const academicExperienceFormCountRef = useRef(academicExperienceFormCount);

      useEffect(
        () => { academicExperienceFieldsRef.current = academicExperienceFields },
        [academicExperienceFields]
      )

      useEffect(
        () => { academicExperienceFormCountRef.current = academicExperienceFormCount },
        [academicExperienceFormCount]
      )

      const addForm = () => {
        setAcademicExperienceFormCount(academicExperienceFormCount + 1)

        if (Array.isArray(academicExperienceFields)) {
          setAcademicExperienceFields([...academicExperienceFields, academicExperienceForm(academicExperienceFormCount + 1)]);
        } else {
          setAcademicExperienceFields([...[academicExperienceFields], academicExperienceForm(academicExperienceFormCount + 1)])
        }
      }

      return (
        <Form className={classes} onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
          e.persist();
        }}>
          {errorMessage}
          {errorMessageShowListing}

          <div id="academicExperienceForm">
            {academicExperienceFields}
          </div>

          <button type="button" onClick={addForm} className={css.addFormBtn} name="add new academic experience"><IconAdd rootClassName={css.addIcon} /></button>


          {/* <FieldCheckboxGroup className={css.features} id={name} name={name} options={options} /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingAcademicExperienceFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingAcademicExperienceFormComponent.propTypes = {
  rootClassName: string,
  intl: intlShape.isRequired,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingAcademicExperienceFormComponent);
