import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingFeaturesPanel.module.css';

const FEATURES_NAME = 'yogaStyles';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingFeaturesPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  
  const yogaStyles = publicData && publicData.yogaStyles;
  const employers = publicData && publicData.employers;
  const initialValues = { yogaStyles, employers };

  const handleHiddenEmployerField = () => {
    const employerForms = document.getElementsByClassName('employer-form');
    const employers = [];
    const employerNames = [];
    const employerSector = [];
    const employerPosition = [];

    Array.from(employerForms).forEach(form => {
      let employer = {}

      const inputs = form.querySelectorAll('input');
      const selects = form.querySelectorAll('select');
      const textAreas = form.querySelectorAll('textarea');
      const checkboxes = form.querySelectorAll('input[type=checkbox]');

      const employerName = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employer';
      }).value;

      const employerDomain = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerDomain';
      }).value;

      const sector = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'sector';
      }).value;

      // const employerDate = Array.from(inputs).find(obj => {
      //   return obj.ariaLabel === 'employerDate';
      // }).value;

      const employerStartDate = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerStartDate';
      }).value;

      const employerEndDate = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerEndDate';
      }).value;

      const position = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'position';
      }).value;

      const achievements = Array.from(textAreas).find(obj => {
        return obj.getAttribute('aria-label') === 'achievements';
      }).value;

      // const currentEmployer = Array.from(checkboxes).find(obj => {
      //   return obj.name.includes('current-employer');
      // }).checked;

      if (/\S/.test(employerName)) {
        employer = { ...employer, employerName, employerDomain, sector, employerStartDate, employerEndDate, position, achievements };
        employers.push({ employer });
        employerNames.push(employerName);
        employerSector.push(sector);
        employerPosition.push(position);
      }
    });

    return { employers, employerNames, employerSector, employerPosition };
  }

  const addInitialValues = () => {
    let sectorInitialValues = {}
    const employerForms = document.getElementsByClassName('employer-form');

    Array.from(employerForms).forEach((form, index) => {
      const inputs = form.querySelectorAll('input');
      const selects = form.querySelectorAll('select');
      const textAreas = form.querySelectorAll('textarea');
      const checkboxes = form.querySelectorAll('input[type=checkbox]');

      const sector = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'sector';
      }).value;

      const sectorLabel = Array.from(selects).find(obj => {
        return obj.getAttribute('aria-label') === 'sector';
      }).name;

      const employerName = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employer';
      }).value;

      const employerNameLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employer';
      }).name;

      const employerDomain = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerDomain';
      }).value;

      const employerDomainLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerDomain';
      }).name;

      // const employerDate = Array.from(inputs).find(obj => {
      //   return obj.ariaLabel === 'employerDate';
      // }).value;

      // const employerDateLabel = Array.from(inputs).find(obj => {
      //   return obj.ariaLabel === 'employerDate';
      // }).name;


      const employerStartDate = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerStartDate';
      }).value;

      const employerStartDateLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerStartDate';
      }).name;

      const employerEndDate = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerEndDate';
      }).value;

      const employerEndDateLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'employerEndDate';
      }).name;

      // const currentEmployer = Array.from(checkboxes).find(obj => {
      //   return obj.name.includes('current-employer');
      // }).checked;

      // const currentEmployerLabel = Array.from(checkboxes).find(obj => {
      //   return obj.name.includes('current-employer');
      // }).name;

      const position = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'position';
      }).value;

      const positionLabel = Array.from(inputs).find(obj => {
        return obj.getAttribute('aria-label') === 'position';
      }).name;

      const achievements = Array.from(textAreas).find(obj => {
        return obj.getAttribute('aria-label') === 'achievements';
      }).value;

      const achievementsLabel = Array.from(textAreas).find(obj => {
        return obj.getAttribute('aria-label') === 'achievements';
      }).name;

      sectorInitialValues = { ...sectorInitialValues, [sectorLabel]: sector, [employerNameLabel]: employerName, [employerDomainLabel]: employerDomain, [employerStartDateLabel]: employerStartDate, [employerEndDateLabel]: employerEndDate,[positionLabel]: position, [achievementsLabel]: achievements }

    });

    if (initialValues && initialValues.employers && initialValues.employers.length < 1) {
      return initialValues
    }

    return { ...initialValues, ...sectorInitialValues };
  }

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingFeaturesForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={addInitialValues()}
        onSubmit={values => {          
          const { employers: employersInfo, employerNames, employerSector, employerPosition  } = handleHiddenEmployerField();
          const { yogaStyles = [], yogaStylesTest = '', employers = []  } = values;
          const reformattedValues = { publicData: { yogaStyles, yogaStylesTest: yogaStyles.toString(), employers: employersInfo, employerNames: employerNames.toString(), employerSector: employerSector.toString(), employerPosition: employerPosition.toString()  }  }
          onSubmit(reformattedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
