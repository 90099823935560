import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldSelect } from '../../components';
import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm 
    keepDirtyOnReinitialize={true}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        initialValues,
        listing
      } = formRenderProps;
      
      console.log("INITIALVALUES");
      console.log(initialValues);

      const { price } = initialValues || {};
      const { currency } = price || {};

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const currentListingCurrency = listing && listing.attributes && listing.attributes.price && listing.attributes.price.currency; 

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const maxPrice = new Money(config.listingMaximumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );

      const maxPriceRequired = validators.moneySubUnitAmountUpTo(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooHigh',
          },
          {
            maxPrice: formatMoney(intl, maxPrice),
          }
        ),
        config.listingMaximumPriceSubUnits
      );

      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired, maxPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      // const min = 0;
      // const max = 20;
      // const step = 1;
      // const handles = [10];

      console.log(config.currencyConfig);

      // let updatedcurrencyConfig = { ...config.currencyConfig, currency: 'GBP' };

      // const currencySelectorChange = (chosenCurrency) => {
      //   console.log("change");
      //   updatedcurrencyConfig = { ...config.currencyConfig, currency: chosenCurrency};
      // }

      // const updatedcurrencyConfig = { ...config.currencyConfig, currency: 'USD'};
      // console.log(updatedcurrencyConfig);

      // let updatedcurrencyConfig = getCurrency();

      const getCurrency = () => {
        const dropdownCurrency = document.getElementById('currencySelector') && document.getElementById('currencySelector').value;
        const chosenCurrency = dropdownCurrency && (dropdownCurrency == 'USD' || dropdownCurrency == 'GBP' || dropdownCurrency == 'EUR') ? dropdownCurrency : (currency ? currency : 'GBP');
        return { ...config.currencyConfig, currency: chosenCurrency };
      }

      let tally = 0;

      const handleOnChange = () => {
        tally = tally = 1;
        const priceInput = document.getElementById('price');

        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(priceInput, '0.00');

        var ev1 = new Event('input', { bubbles: true});
        priceInput.dispatchEvent(ev1);
      }

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <label for="currencySelector">currency</label>
          <select id='currencySelector' name='currencySelector' className={css.field} initialValue={currency} defaultValue={currency} label="currency selector" aria-label="currency selector" onChange={handleOnChange}>
            <option value="USD" selected={currency === 'USD' && tally == 0}>$</option>
            <option value="GBP" selected={currency === 'GBP' && tally == 0}>£</option>
            <option value="EUR" selected={currency === 'EUR' && tally == 0}>€</option>
          </select>


          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={getCurrency()}
            validate={priceValidators}
            autoComplete="off"
          />

          {/* <FormSpy onChange={formState => {
            if (formState.dirty) {
              console.log('form values changed to:', formState.values);
              console.log(formState.values['price-slider'][0]);
              console.log(document.getElementById('price').value);
              document.getElementById('price').value = 1000000;
              console.log("here");
            }
          }} /> */}

          {/* <FieldRangeSlider
            id="price-slider"
            name="price-slider"
            label="Select price"
            min={min}
            max={max}
            step={step}
            handles={handles}
          /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
