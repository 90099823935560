import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldBirthdayInput, FieldPhoneNumberInput } from '../../components';
import CustomCertificateSelectFieldMaybe from './CustomCertificateSelectFieldMaybe';
import * as validators from '../../util/validators';


import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 140;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        certificateOptions,
        initialValues,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        currentUser,
      } = formRenderProps;

      console.log(currentUser);

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });

      const headlineMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.headline' });
      const headlinePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.headlinePlaceholder',
      });
      const headlineRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.headlineRequired',
      });

      const reasonMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.reason' });
      const reasonPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.reasonPlaceholder',
      });
      const reasonRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.reasonRequired',
      });

      // const telephoneRequiredMessage = intl.formatMessage({
      //   id: 'EditListingDescriptionForm.telephoneRequired',
      // });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      console.log(submitDisabled);
      console.log(invalid);
      console.log(disabled);
      console.log(submitInProgress);

      const dobRequired = validators.required('A valid date is required');
      const minAge = 18;
      const minAgeRequired = validators.ageAtLeast(`Age should be at least ${minAge}`, minAge);

      const { day, month, year } = initialValues && initialValues.dateOfBirth || {};
      const valueFromFormDate = day && month && year ? new Date(year, month, day) : undefined;

      console.log(valueFromFormDate);

      console.log(initialValues);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          {/* <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <CustomCertificateSelectFieldMaybe
            id="certificate"
            name="certificate"
            certificateOptions={certificateOptions}
            intl={intl}
          /> */}



          {/* <FieldTextInput
            id="title"
            name="title"
            className={css.field}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          /> */}

          {/* <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          /> */}

          <FieldTextInput
            id="headline"
            name="headline"
            className={css.field}
            type="textarea"
            label={headlineMessage}
            placeholder={headlinePlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(headlineRequiredMessage))}
            autoComplete="off"
            autoFocus
          />

          <FieldTextInput
            id="reason"
            name="reason"
            className={css.field}
            type="textarea"
            label={reasonMessage}
            placeholder={reasonPlaceholderMessage}
            // maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(reasonRequiredMessage))}
            autoComplete="off"
          />

          {/* <FieldTextInput
            id="description"
            name="description"
            className={css.field}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          /> */}

          <FieldBirthdayInput
            id="dateOfBirth"
            className={css.field}
            name="dateOfBirth"
            label="date of birth"
            valueFromForm={valueFromFormDate}
            validate={composeValidators(dobRequired, minAgeRequired)}
            autoComplete="off"
          />

          {/* <FieldPhoneNumberInput
            id="telephone"
            className={css.field}
            name="telephone"
            label="telephone"
            validate={composeValidators(required(telephoneRequiredMessage))}
            autoComplete="off"
          /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
