import React, { useState, useEffect, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import config from '../../config';
import { Button, Form, FieldTextInput, FieldSelect, IconClose, IconAdd, FieldRadioButton, FieldCheckbox } from '../../components';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        invalid,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl, 
        initialValues,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      let { employers } = initialValues;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('yogaStyles', filterConfig);

      const higherEducationMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.higherEductaion' });
      const higherEducationPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.higherEducationPlaceholder',
      });

      const employerMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.employer' });
      const employerPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.employerPlaceholder',
      });
      
      const employerDateMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.employerDate' });
      const employerDatePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.employerDatePlaceholderMessage',
      });

      const employerStartDateMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.employerStartDate' });
      const employerStartDatePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.employerStartDatePlaceholderMessage',
      });

      const employerEndDateMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.employerEndDate' });
      const employerEndDatePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.employerEndDatePlaceholderMessage',
      });

      const sectorMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.sector' });
      const sectorPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.sectorPlaceholder',
      });

      const positionMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.position' });
      const positionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.positionPlaceholder',
      });

      const companyInfoMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.companyInfo' });
      const companyInfoPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.companyInfoPlaceholder',
      });

      const achievementsMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.achievements' });
      const achievementsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.achievementsPlaceholder',
      });


      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: 60,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, 60);

      const employerNameRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.employerNameRequiredMessage',
      });

      const positionRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.positionRequiredMessage',
      });

      const sectorRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.sectorRequiredMessage',
      });

      const employerDateRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.employerDateRequiredMessage',
      });

      const employerStartDateRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.employerStartDateRequiredMessage',
      });

      const achievementsRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.achievementsRequiredMessage',
      });

      const clearDropdown = (dropdown) => {
        document.getElementById(dropdown).innerHTML = '';
      }

      const handleDropdownClick = (e, dropdownId, index) => {
        const companyDataStr = document.getElementById(e.target.id).getAttribute('data-company');
        const companyDataJSON = JSON.parse(companyDataStr);

        // console.log(document.getElementById(`${companyDataJSON.inputId}-${index}`));
        console.log(`${ companyDataJSON.inputId }`);

        document.getElementById(companyDataJSON.inputId).value = companyDataJSON.name;
        document.getElementById(`${companyDataJSON.inputId}-domain`).value = companyDataJSON.domain;
        clearDropdown(dropdownId);

      }

      const clearDomainField = (containerId) => {
        document.getElementById(`${containerId}-domain`).value = '';
      }

      const populateDropdown = (results, container) => {
        const dropdown = document.getElementById(`${container}-dropdown`);
        let dropdownItems = ''

        results.forEach((result, index) => {
          const companyObjStr = JSON.stringify({
            logo: result.logo,
            name: result.name,
            domain: result.domain,
            inputId: container
          });
          const dropdownItem = `<div id="${container}-dropdown-item-${index}" class="dropdown-item" data-company='${companyObjStr}'><img src="${result.logo}" id="${container}-dropdown-item-${index}-img" onerror="this.style.display='none'" data-company='${companyObjStr}'/>${result.name} <span id="${container}-dropdown-item-${index}-span" data-company='${companyObjStr}'>${result.domain}</span></div>`;

          dropdownItems = dropdownItems + dropdownItem;
        });

        dropdown.innerHTML = dropdownItems;
      }

      const handleOnInput = (e) => {
        const containerId = e.target.id;
        clearDomainField(containerId);

        fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=:${e.target.value}`)
          .then(res => res.json())
          .then((result) => {
            console.log(result, containerId);
            populateDropdown(result, containerId);
          }),
          (error) => {
            console.log("logo error ", error);
          }
      }
      

      const employerNameClass = `${css.field} employer-name-input`;
      const employerFormClass = `${css.form} employer-form`

      const employerForm = (count) => {
        return (
          <div className={employerFormClass} key={`employer-field-container-${count}`}>
            <div>
              <FieldTextInput
                id={`employer${count}`}
                name={`employer${count}`}
                className={employerNameClass}
                type="text"
                label={employerMessage}
                placeholder={employerPlaceholderMessage}
                autoFocus
                aria-label="employer"
                validate={composeValidators(required(employerNameRequiredMessage))}
                onInput={e => handleOnInput(e)}
                onFocus={e => e.preventDefault}
                autoComplete="off"
              />
              <div id={`employer${count}-dropdown`} className={css.dropdown} onClick={e => handleDropdownClick(e, `employer${count}-dropdown`, count)} />

              <FieldTextInput
                id={`employer${count}-domain`}
                name={`employer${count}-domain`}
                className={css.employerDomain}
                type="text"
                label="domain"
                aria-label="employerDomain"
                autoComplete="off"
              />
            </div>

            <FieldTextInput
              id={`position${count}`}
              name={`position${count}`}
              className={css.field}
              type="text"
              label={positionMessage}
              placeholder={positionPlaceholderMessage}
              aria-label="position"
              validate={composeValidators(required(positionRequiredMessage))}
              autoComplete="off"
            />

            <FieldSelect id={`sector${count}`} name={`sector${count}`} className={css.field} defaultValue="medicine" label="sector" aria-label="sector">
              <optgroup label="healthcare">
                <option value="medicine">medicine</option>
                <option value="dentistry">dentistry</option>
                <option value="pharmacy">pharmacy</option>
                <option value="veterinary">venterinary</option>
              </optgroup>
              <optgroup label="engineering">
                <option value="mechanical">mechanical</option>
                <option value="aeronautical">aeronautical</option>
                <option value="civil">civil</option>
                <option value="electrical">electrical</option>
              </optgroup>
              <optgroup label="business">
                <option value="management consultancy">management consultancy</option>
                <option value="general management">general management</option>
                <option value="start-ups">start-ups</option>
              </optgroup>
              <optgroup label="finance">
                <option value="investment banking">investment banking</option>
                <option value="investment management">investment management</option>
                <option value="sales + trading">sales + trading</option>
                <option value="investment research">investment research</option>
                <option value="risk management">risk management</option>
                <option value="chartered accountants">chartered accountants</option>
                <option value="general finance">general finance</option>
                <option value="actuarial scientist">actuarial scientist</option>
              </optgroup>
              <optgroup label="law">
                <option value="solicitor">solicitor</option>
                <option value="barrister">barrister</option>
              </optgroup>
              <optgroup label="technology">
                <option value="developer/ software engineer">developer/ software engineer</option>
                <option value="support">support</option>
              </optgroup>
            </FieldSelect>
            {/* <FieldTextInput
              id={`employerDate${count}`}
              name={`employerDate${count}`}
              className={css.field}
              type="text"
              label={employerDateMessage}
              placeholder={employerDatePlaceholderMessage}
              aria-label="employerDate"
              validate={composeValidators(required(employerDateRequiredMessage))}
              autoComplete="off"
            /> */}

            <div className={css.dateFields}>

              <FieldTextInput
                id={`employerStartDate${count}`}
                name={`employerStartDate${count}`}
                className={css.field}
                type="text"
                label={employerStartDateMessage}
                placeholder={employerStartDatePlaceholderMessage}
                aria-label="employerStartDate"
                validate={composeValidators(required(employerStartDateRequiredMessage))}
                autoComplete="off"
              />

              <FieldTextInput
                id={`employerEndDate${count}`}
                name={`employerEndDate${count}`}
                className={css.field}
                type="text"
                label={employerEndDateMessage}
                placeholder={employerEndDatePlaceholderMessage}
                aria-label="employerEndDate"
                autoComplete="off"
              />
            </div>

            

            {/* <FieldCheckbox id={`current-employer-${count}`} name={`current-employer-${count}`} label="current employer" value="current" /> */}

            <FieldTextInput
              id={`achievements${count}`}
              name={`achievements${count}`}
              className={css.field}
              type="textarea"
              label={achievementsMessage}
              placeholder={achievementsPlaceholderMessage}
              aria-label="achievements"
              validate={composeValidators(required(achievementsRequiredMessage))}
              autoComplete="off"
            />
            <button type="button" className={css.removeButton} name="remove experience"  onClick={
              () => {
                const filterObj = Array.isArray(employerFieldsRef.current) ? employerFieldsRef.current : [employerFieldsRef.current];
                const data = (filterObj).filter(obj => obj.key !== `employer-field-container-${count}`);
                setEmployerFormFields(data);
              }
            }><IconClose rootClassName={css.closeIcon} /></button>
          </div>
        )
      }

      const handleCheckbox = (id, isCurrentEmployer) => {
        console.log(id);
        console.log(isCurrentEmployer);
        console.log(document.getElementById(id));

        const checkbox = document.getElementById(id);
        
        if(checkbox) {
          checkbox.checked = isCurrentEmployer;
          console.log("pls");
          console.log(checkbox.checked);
        }
        
        // console.log(e);
        // console.log(e.target);
        // console.log(e.target.value);
        // console.log(e.target.checked);
        // document.getElementById(id).checked = isCurrentEmployer
      }

      const test = () => {
        console.log("hi");
      }

      const handleCheckBoxClick = (e) => {
        console.log(e);
      }

      const initialForm = (count) => {
        if (employers && employers.length > 0) {
          const existingEmployersFields =
            employers.map((item, index) => {
              const { employerName, employerDomain, sector, employerDate, employerStartDate, employerEndDate, currentEmployer, position, achievements } = item.employer;

              return (
                <div className={employerFormClass} key={`employer-field-container-${index}`}>
                  <div>
                    <FieldTextInput
                      id={`employer${index}`}
                      name={`employer${index}`}
                      className={employerNameClass}
                      type="text"
                      label={employerMessage}
                      placeholder={employerPlaceholderMessage}
                      autoFocus
                      aria-label="employer"
                      initialValue={employerName}
                      validate={composeValidators(required(employerNameRequiredMessage))}
                      onInput={e => handleOnInput(e)}
                      onFocus={e => e.preventDefault}
                      autoComplete="off"
                    />
                    <div id={`employer${index}-dropdown`} className={css.dropdown} onClick={e => handleDropdownClick(e, `employer${index}-dropdown`, index)}/>
                    
                    <FieldTextInput
                      id={`employer${index}-domain`}
                      name={`employer${index}-domain`}
                      className={css.employerDomain}
                      type="text"
                      label="domain"
                      aria-label="employerDomain"
                      initialValue={employerDomain}
                      autoComplete="off"
                    />
                  </div>

                  {/* <FieldTextInput
                    id={`employer${count}-logoApi`}
                    name={`employer${count}-logoApi`}
                    type="hidden"
                    aria-label="employer"
                    autoComplete="off"
                  /> */}
                  
                  <FieldTextInput
                    id={`position${index}`}
                    name={`position${index}`}
                    className={css.field}
                    type="text"
                    label={positionMessage}
                    placeholder={positionPlaceholderMessage}
                    aria-label="position"
                    initialValue={position}
                    validate={composeValidators(required(positionRequiredMessage))}
                    autoComplete="off"
                  />

                  <FieldSelect id={`sector${index}`} name={`sector${index}`} className={css.field} label="sector" aria-label="sector" initialValue={sector} validate={composeValidators(required(sectorRequiredMessage))}>
                    <optgroup label="healthcare">
                      <option value="medicine">medicine</option>
                      <option value="dentistry">dentistry</option>
                      <option value="pharmacy">pharmacy</option>
                      <option value="veterinary">venterinary</option>
                    </optgroup>
                    <optgroup label="engineering">
                      <option value="mechanical">mechanical</option>
                      <option value="aeronautical">aeronautical</option>
                      <option value="civil">civil</option>
                      <option value="electrical">electrical</option>
                    </optgroup>
                    <optgroup label="business">
                      <option value="management consultancy">management consultancy</option>
                      <option value="general management">general management</option>
                      <option value="start-ups">start-ups</option>
                    </optgroup>
                    <optgroup label="finance">
                      <option value="investment banking">investment banking</option>
                      <option value="investment management">investment management</option>
                      <option value="sales + trading">sales + trading</option>
                      <option value="investment research">investment research</option>
                      <option value="risk management">risk management</option>
                      <option value="chartered accountants">chartered accountants</option>
                      <option value="general finance">general finance</option>
                      <option value="actuarial scientist">actuarial scientist</option>
                    </optgroup>
                    <optgroup label="law">
                      <option value="solicitor">solicitor</option>
                      <option value="barrister">barrister</option>
                    </optgroup>
                    <optgroup label="technology">
                      <option value="developer/ software engineer">developer/ software engineer</option>
                      <option value="support">support</option>
                    </optgroup>
                  </FieldSelect>

                  {/* <FieldTextInput
                    id={`employerDate${index}`}
                    name={`employerDate${index}`}
                    className={css.field}
                    type="text"
                    label={employerDateMessage}
                    placeholder={employerDatePlaceholderMessage}
                    aria-label="employerDate"
                    initialValue={employerDate}
                    validate={composeValidators(required(employerDateRequiredMessage))}
                    autoComplete="off"
                  /> */}

                  <div className={css.dateFields}>
                    <FieldTextInput
                      id={`employerStartDate${index}`}
                      name={`employerStartDate${index}`}
                      className={css.field}
                      type="text"
                      label={employerStartDateMessage}
                      placeholder={employerStartDatePlaceholderMessage}
                      aria-label="employerStartDate"
                      initialValue={employerStartDate}
                      validate={composeValidators(required(employerStartDateRequiredMessage))}
                      autoComplete="off"
                    />

                    <FieldTextInput
                      id={`employerEndDate${index}`}
                      name={`employerEndDate${index}`}
                      className={css.field}
                      type="text"
                      label={employerEndDateMessage}
                      placeholder={employerEndDatePlaceholderMessage}
                      aria-label="employerEndDate"
                      initialValue={employerEndDate}
                      autoComplete="off"
                    />

                  </div>

                  

                  
                    {/* <label>Scales
                      <input type="checkbox" id={`scales-${index}`} name="scales" value={`current-employer-${index}`} onChange={e => handleCheckbox(e)} defaultChecked={currentEmployer}/>
                    </label> */}

                  {/* <FieldCheckbox id={`current-employer-${index}`} name={`current-employer-${index}`} label="current employer" value="current employer" aria-label="current employer" /> */}

                  <FieldTextInput
                    id={`achievements${index}`}
                    name={`achievements${index}`}
                    className={css.field}
                    type="textarea"
                    label={achievementsMessage}
                    placeholder={achievementsPlaceholderMessage}
                    aria-label="achievements"
                    initialValue={achievements}
                    validate={composeValidators(required(achievementsRequiredMessage))}
                    autoComplete="off"
                  />

                  


                

                  <button type="button" name="remove experience" className={css.removeButton} onClick={
                    () => {
                      const data = (employerFieldsRef.current).filter(obj =>  obj.key !== `employer-field-container-${index}`);
                      console.log(data);
                      setEmployerFormFields(data);
                    }
                  }><IconClose rootClassName={css.closeIcon} /></button>
                </div>
              );

            }); 

          return existingEmployersFields;

        }

        return (employerForm(count))


      }

      const employerFormCountDefaultValue = employers && employers.length > 0 ? employers.length - 1  : 0;

      const [employerFormCount, setEmployerFormCount] = useState(employerFormCountDefaultValue);
      const [employerFormFields, setEmployerFormFields] = useState(initialForm(employerFormCount));
      const employerFieldsRef = useRef(employerFormFields);
      const employerFormCountRef = useRef(employerFormCount);

      useEffect(
        () => { employerFieldsRef.current = employerFormFields },
        [employerFormFields]
      )

      useEffect(
        () => { employerFormCountRef.current = employerFormCount },
        [employerFormCount]
      )

      const addForm = () => {
        setEmployerFormCount(employerFormCount + 1)

        if (Array.isArray(employerFormFields)) {
          setEmployerFormFields([...employerFormFields, employerForm(employerFormCount + 1)]);
        } else {
          setEmployerFormFields([...[employerFormFields], employerForm(employerFormCount + 1)])
        }
      }

      return (
        <Form className={classes} onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
          e.persist();
        }}>
          {errorMessage}
          {errorMessageShowListing}

          <div id="employerForm">
            {employerFormFields}
          </div>

          <button type="button" className={css.addFormBtn} name="add new experience" onClick={addForm}><IconAdd rootClassName={css.addIcon} /></button>


          {/* <FieldCheckboxGroup className={css.features} id={name} name={name} options={options} /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  intl: intlShape.isRequired,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingFeaturesFormComponent);
